<template>
  <div ref="donut" v-resize:debounce="resized" class="donut">
    <div class="background" />


    <svg>
      <g ref="root" class="root tooltip-target" />
    </svg>
    <transition name="fade">
      <div
        v-if="tooltipData"
        class="custom-tooltip tooltip"
        x-placement="top"
        :style="{top: tooltipData.top, left: tooltipData.left}"
      >
        <div class="tooltip-arrow" />
        <div class="tooltip-inner">{{ tooltipData.data.name }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
/*

*/
import d3 from '../utils/d3Importer'
import resize from 'vue-resize-directive'
import { isBadImgLoaderBrowser } from '@/utils/device'

export default {
  name: 'Donut',
  directives: {
    resize: resize,
  },
  model: {
    prop: 'selection',
    event: 'change',
  },
  props: {
    data: Array,
    selection: Object,
    radiusPercent: {
      default: 0.8,
    },
  },
  data() {
    return {
      size: { width: 0, height: 0 },
      showSubs: true,
      currentAngle: 0,
      tooltipData: false,
      outterArc: null,
      subArc: null,
    }
  },
  computed: {
    // size (){
    //   return {width:this.$refs.donut.clientWidth, height: this.$refs.donut.clientHeight}
    // }
  },
  watch: {
    data: function() {
      this.draw()
    },
    selection: function() {
      this.draw()
    },
  },
  methods: {
    resized() {
      this.size = { width: this.$refs.donut.clientWidth, height: this.$refs.donut.clientHeight }
      this.draw()
    },
    draw() {
      let self = this
      if (!this.$refs.donut) return

      const width = this.size.width
      const height = this.size.height
      const r = (Math.min(height, width) * this.radiusPercent) / 2
      const innerR = r * 0.3
      const subR = r * 0.6

      if (!this.outterArc) {
        this.outterArc = d3.arc()
      }
      var outterArc = this.outterArc
        .innerRadius(innerR)
        .outerRadius(r)
        .padAngle(0.01)

      if (!this.subArc) {
        this.subArc = d3.arc()
      }
      var subArc = this.subArc
        .innerRadius(innerR)
        .outerRadius(subR)
        .padAngle(0)

      // var innerarc = d3
      //   .arc()
      //   .innerRadius(innerR-1)
      //   .outerRadius(()=>innerR)
      //   .padAngle(0);

      var pie = d3
        .pie()
        .sort(this.compareFamilies)
        .value(function(d) {
          return d.subFamilies.reduce((count, sf) => count + sf.count, 0)
        })
        .startAngle(0)
        .endAngle(2 * Math.PI)

      var arcTween = function(a) {
        let node = this.parentNode
        var i = d3.interpolate(node._current, a)
        node._current = i(0)
        return function(t) {
          return outterArc(i(t))
        }
      }
      var subArcTween = function(a) {
        let node = this.parentNode
        var i = d3.interpolate(node._current, a)
        let r0 = self.showSubs ? subR : innerR
        var ri = d3.interpolate(node._current.data.radius || r0, a.data.radius)
        var ease
        if (node._current.data.radius < a.data.radius) ease = d3.easeBackOut
        else ease = d3.easeBackIn
        node._current = i(0)
        node._current.data.radius = ri(0)
        return function(t) {
          return subArc.outerRadius(ri(ease(t)))(i(t))
        }
      }
      var arcTweenDeep = function(a) {
        let node = this.parentNode.parentNode
        var i = d3.interpolate(node._current, a)
        node._current = i(0)
        return function(t) {
          return outterArc(i(t))
        }
      }

      function findNeighborArc(i, data0, data1, key) {
        var d
        // eslint-disable-next-line
        return (d = findPreceding(i, data0, data1, key))
          ? // eslint-disable-next-line
            { data: d.data, startAngle: d.endAngle, endAngle: d.endAngle }
          : // eslint-disable-next-line
          (d = findFollowing(i, data0, data1, key))
          ? // eslint-disable-next-line
            { data: d.data, startAngle: d.startAngle, endAngle: d.startAngle }
          : null
      }

      // Find the element in data0 that joins the highest preceding element in data1.
      function findPreceding(i, data0, data1, key) {
        var m = data0.length
        // eslint-disable-next-line
        while (--i >= 0) {
          var k = key(data1[i])
          for (var j = 0; j < m; ++j) {
            if (key(data0[j]) === k) return data0[j]
          }
        }
      }

      // Find the element in data0 that joins the lowest following element in data1.
      function findFollowing(i, data0, data1, key) {
        var n = data1.length,
          m = data0.length
        // eslint-disable-next-line
        while (++i < n) {
          var k = key(data1[i])
          for (var j = 0; j < m; ++j) {
            if (key(data0[j]) === k) return data0[j]
          }
        }
      }

      let donutRoot = d3.select(this.$refs.root)

      let key = d => {
        return d.data.family.id
      }
      let items = donutRoot.selectAll('.item')
      var data0 = items.data(),
        data1 = pie(this.data)

      items = items.data(data1, key)

      let itemsEnter = items
        .enter()
        .append('g')
        .attr('class', 'item')

      itemsEnter.each(function(d, i) {
        this._current = findNeighborArc(i, data0, data1, key) || {
          data: {},
          startAngle: d.startAngle + (d.endAngle - d.startAngle) / 2,
          endAngle: d.startAngle + (d.endAngle - d.startAngle) / 2,
        }
      })

      itemsEnter
        .append('path')
        .attr('class', 'main-wedge')
        .attr('fill', function(d) {
          return d.data.family.color
        })

      itemsEnter.append('g').attr('class', 'image-container')

      itemsEnter
        .append('clipPath')
        .attr('class', 'clip-path')
        .attr('id', d => {
          return 'donut-clip-' + this._uid + '-' + d.data.family.id
        })
        .append('path')
        .attr('fill', function() {
          return '#000'
        })

      let resizeImage = selection => {
        selection.each(function(d) {
          let centroid = outterArc.centroid(d)
          let x = centroid[0]
          let y = centroid[1]
          let theta = Math.atan2(y, x)
          let imageSize = Math.abs(d.endAngle - d.startAngle) > Math.PI ? 3 * r : 3 * r
          x = (Math.cos(theta) * r) / 2 - imageSize / 2
          y = (Math.sin(theta) * r) / 2 - imageSize / 2

          d3.select(this)
            .attr('width', imageSize)
            .attr('height', imageSize)
            .attr('transform-origin', x + imageSize / 2 + ' ' + (y + imageSize / 2))
            .attr('x', x)
            .attr('y', y)
        })
      }
      let instanceId = this._uid
      itemsEnter.each(function(d) {
        const img = document.createElementNS('http://www.w3.org/2000/svg', 'image')
        const href = d.data.family.imageUrl

        img.onload = () => {
          d3.select(this)
            .select('.image-container')
            .append(() => img)
            .call(resizeImage)
          d3.select(this).attr(
            'clip-path',
            'url(#donut-clip-' + instanceId + '-' + d.data.family.id + ')'
          )
        }

        img.onerror = () => {
          d3.select(this).classed('no-image', true)
        }

        if (href) img.setAttributeNS('http://www.w3.org/1999/xlink', 'href', href)

        // BASURA EXPLORER QUE NO DISPARA "onload"...
        // console.log("browser",isBadImgLoaderBrowser)
        if (isBadImgLoaderBrowser) {
          this.timer = setTimeout(() => {
            img.onload()
          }, 400)
        }
      })

      itemsEnter.select('.main-wedge').on('click', (e,d) => {
        if (this.selection && this.selection.id === d.data.family.id) self.$emit('change', null)
        else self.$emit('change', d.data.family)
      })
      items.exit().remove()
      items = items.merge(itemsEnter)
      let rawItems = items
      items = items.transition().duration(600)
      items.select('path').attrTween('d', arcTween)
      items
        .select('.clip-path')
        .select('path')
        .attrTween('d', arcTweenDeep)

      items.select('image').call(resizeImage)

      ///////////////////////////////////////////

      // SUBWEDGES ...
      let subItems = rawItems.selectAll('.sub-wedge').data(
        d => {
          var subPie = d3
            .pie()
            .sort(this.compareSubFamilies)
            .value(function(dd) {
              return dd.count
            })
            .startAngle(d.startAngle)
            .endAngle(d.endAngle)

          let sp = subPie(d.data.subFamilies)
          sp.forEach(n => {
            // console.log(d.startAngle)
            n.data.offset = n.startAngle + Math.PI + (n.endAngle - n.startAngle) / 2 //-data1[0].startAngle//+(n.endAngle-n.startAngle)/2
            while (n.data.offset > 2 * Math.PI) n.data.offset -= 2 * Math.PI
            while (n.data.offset < 0) n.data.offset += 2 * Math.PI
            n.data.offset = (50 * n.data.offset) / (2 * Math.PI)
            n.data.radius = this.showSubs ? subR : innerR
            n.data.family = d.data.family //.id
          })

          d.subdata0 = d.subdata1 || []
          d.subdata1 = sp

          return sp
        },
        dd => dd.data.subFamily.id
      )
      let subItemsEnter = subItems
        .enter()
        .append('g')
        .attr('class', 'sub-wedge')
      subItemsEnter
        .append('path')
        .attr('class', 'sub-wedge-path')
        .attr('d', d => {
          return subArc.outerRadius(() => (this.showSubs ? subR : innerR))(d)
        })
        .attr('opacity', '0')

      subItemsEnter.each(function(d, i) {
        this._current = findNeighborArc(
          i,
          d3.select(this.parentNode).datum().subdata0,
          d3.select(this.parentNode).datum().subdata1,
          (d, i) => i
        ) || {
          data: {},
          startAngle: d.startAngle + (d.endAngle - d.startAngle) / 2,
          endAngle: d.startAngle + (d.endAngle - d.startAngle) / 2,
        }
      })

      subItemsEnter.on('mouseenter', function(e, obj) {
        // if(this.props.onSelectSub) this.props.onSelectSub(d.data.id,d.data.family)
        let c = subArc.centroid({
          startAngle: obj.startAngle + self.currentAngle - Math.PI / 2,
          endAngle: obj.endAngle + self.currentAngle - Math.PI / 2,
        })
        self.tooltipData = {
          data: obj.data.subFamily,
          top: c[1] + self.size.height / 2 - self.size.height * 0.05 + 'px',
          left: c[0] + self.size.width / 2 + 'px',
        }
      })
      subItemsEnter.on('mouseout', (event, d) => {
        if (this.tooltipData && this.tooltipData.data === d.data.subFamily) this.tooltipData = null
      })
      subItemsEnter.on('click', (e,d) => {
        this.tooltipData = null
        if (this.selection && this.selection.id === d.data.family.id) self.$emit('change', null)
        else self.$emit('change', d.data.family)
      })

      // let subItemsWedgesEnter = subItemsEnter.append('g')
      //   .attr('class','sub-family-wedge')
      //   .attr('opacity',0)
      // subItemsWedgesEnter.append('path')
      //     .attr('class','sub-family-wedge-path')
      // subItemsWedgesEnter.append('text')
      //   .attr('class','sub-family-text')
      //   // .attr('dx',(d)=>-d.data.name.length*3.4)
      //   .append('textPath')
      //     //href={"#text-circle-"+this.instanceId}
      //     // startOffset={(100-textOffset)/2+'%'}
      //     .attr('href',"#text-small-circle-"+this.instanceId)
      //     .attr('alignment-baseline','middle')
      //     .attr("text-anchor","middle")
      //     .attr('startOffset',(d)=>{
      //       // let textOffset = 100*(currentOffset+(family.notes.length-1)/2)*this.props.sdTheta/(2*Math.PI)
      //       return d.data.offset+'%'
      //     })

      // subItemsEnter.append('path')
      //   .attr('class','sub-wedge-selection')
      //   .attr("d", (d)=>{
      //     return smallArcSelection.outerRadius(subR)(d)
      //   })
      //   .attr('stroke','#fff')
      //   .attr('stroke-width',4)
      //   .attr('fill','none')
      //   .attr('opacity','0')

      subItems.exit().remove()

      subItems = subItems.merge(subItemsEnter)

      subItems
        .select('.sub-wedge-path')
        .transition()
        .duration(700)
        .attrTween('d', subArcTween)
        .attr('opacity', '0.7')
        // .attr('fill',(d)=>Utils.hexToRGB(d.data.color || '#ffffff',0.7))
        .attr('fill', d => {
          return d.data.subFamily.color
        })

      // subItems.select('.sub-wedge-selection')
      //   .attr("d", (d)=>{
      //     return smallArcSelection.outerRadius(subR)(d)
      //   })
      //   .transition()
      //   .attr('opacity',(d)=>{
      //     if(this.showSubs && d.data.id === this.props.subSelection && d.data.family === this.props.selection) return 1
      //     else return 0
      //   })

      // subItems.each(function(d){
      //   if(self.showSubs && d.data.id === self.props.subSelection && d.data.family === self.props.selection) this.parentNode.appendChild(this);
      // })

      // subItems.select('.sub-family-wedge').select('path')
      //   .attr("d", (d) => {
      //     return subArc(d)
      //   })
      //   // .attr('fill','rgba(0,0,0,0.8')
      //   .attr('fill',(d)=>d.data.color)

      // subItems.select('.sub-family-wedge').transition()
      //   .duration(this.showSubs?600:200)
      //   .delay(this.showSubs?600:0)
      //   .attr('opacity',this.showSubs?0.9:0)

      // subItems.select('.sub-family-wedge').select('.sub-family-text').select('textPath')
      //   .text((d,i)=>{
      //     let wedgeLength = (d.endAngle - d.startAngle)*this.subR
      //     let wordLength = d.data.name.length*8
      //     let familyName = wordLength<wedgeLength? d.data.name:d.data.name.substr(0,Math.floor(wedgeLength/8)-1)+'...'
      //     return familyName
      //   })
      //   .transition()
      //   .duration(600)
      //   .attr('startOffset',(d)=>{
      //     return (d.data.offset)+'%'
      //   })

      rawItems.classed('unselected', d => {
        return self.selection && self.selection != d.data.family
      })

      let wedge = data1.find(d => {
        return d.data.family === self.selection
      })
      if (wedge) {
        let newAngle = -(wedge.startAngle + (wedge.endAngle - wedge.startAngle) / 2) - Math.PI / 2 // ????? + (this.props.anchorOffset || 0)
        if (!this.currentAngle) {
          this.currentAngle = 0
        }
        while (newAngle - this.currentAngle > Math.PI) {
          newAngle = newAngle - 2 * Math.PI
        }
        while (newAngle - this.currentAngle < -Math.PI) {
          newAngle = newAngle + 2 * Math.PI
        }
        this.currentAngle = newAngle
      }

      let rootSel = d3.select(this.$refs.root)
      if (rootSel.node().getAttribute('transform') !== null)
        rootSel = rootSel
          .transition()
          .duration(800)
          .ease(d3.easeCubicInOut)
      rootSel.attr(
        'transform',
        'translate(' +
          width / 2 +
          ',' +
          height / 2 +
          ') rotate(' +
          ((180 * this.currentAngle) / Math.PI - 90) +
          ')'
      )
    },
  },
}
</script>

<style scoped lang="stylus">
.donut
  position: relative

  .background
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    pointer-events: all // FOR TESITNG TOOLTIP ONLY

  svg
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    pointer-events: none

    /deep/ .item
      pointer-events: all

      .sub-wedge-path
        // pointer-events none

      .image-container
        pointer-events: none

      &.unselected
        opacity: 0.2

  .custom-tooltip
    position: absolute
    transform: translate(-50%, -50%)
    pointer-events: none

    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity 0.2s

    &.fade-enter,
    &.fade-leave-to
      opacity: 0
</style>