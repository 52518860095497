<template>
  <div
    class="perfume-view"
    itemscope
    itemtype="http://schema.org/Product"
  >
    <div class="perfume-view-inner">
      <breadcrumbs-header v-if="!isMobile" :links="breadcrumbs" />
      <div v-if="errorCode === 404" class="not-found">
        <h1>{{ $t('perfume_not_found') }}</h1>
      </div>
      <basic-loading v-else-if="isPerfumeUpdating || !perfume" />
      <div v-else class="perfume-view-wrapper">
        <!-- Header -->
        <div class="section">
          <div class="perfume-header">
            <div class="perfume-brand-container">
              <div class="perfume-brand">{{ brand }}</div>
              <template v-if="perfume.productLine">
                <div class="perfume-separator">|</div>
                <div class="perfume-line"> {{ perfume.productLine }}</div>
              </template>
            </div>
            <div itemprop="name" class="perfume-name">{{ name }}</div>
            <div v-if="classification" itemprop="name" class="perfume-classification">{{ classification }}</div>
            <div v-if="isMobile" class="icon-close" @click="handleBack" />
          </div>
        </div>
        <!-- Perfume Image -->
        <div class="section image">
          <div class="left">
            <div
              class="perfume-image"
              itemprop="image"
              :style="'background-image: url(' + imageUrl + ')'"
            />
            <div v-if="isMobile" class="actions">
              <!-- ONLY WITH LOGIN -->
              <!--button class="btn btn--secondary btn--ghost" @click="handleClickWishlist">{{ !hasPerfume ? $t('perfume_view_wishlist') : $t('perfume_view_remove_wistlist') }}</!--button-->
              <button class="btn btn--tertiary" @click="handleClickGoPerfume">{{ $t('perfume_view_go_product') }}</button>
            </div>
          </div>
          <div class="right">
            <div class="section-title">{{ $t('perfume_view_why') }}</div>
            <why-this-reco-desc
              v-if="!isSelectedPerfumesUpdating && perfume"
              class="section-text"
              :data="perfume"
              :recommended-perfumes="selectedPerfumes"
            />

            <div class="ingredients-list">
              <ingredient-item v-for="ingredient in mainIngredients" :key="ingredient.id" :data="ingredient" />
            </div>
            <div v-if="!isMobile" class="actions">
              <!-- ONLY WITH LOGIN -->
              <!--button class="btn btn--secondary btn--ghost" @click="handleClickWishlist">{{ !hasPerfume ? $t('perfume_view_wishlist') : $t('perfume_view_remove_wistlist') }}</!--button-->
              <button class="btn btn--tertiary" @click="handleClickGoPerfume">{{ $t('perfume_view_go_product') }}</button>
            </div>
          </div>
        </div>
        <!-- First Section -->
        <div class="section">
          <div class="left">
            <!-- mobile sub-section -->
            <div v-if="isMobile" class="sub-section">
              <div class="section-title">{{ $t('perfume_view_families_and_subfamilies') }}</div>
              <div class="perfume-families-groups">
                <div class="donut-wrapper">
                  <donut
                    ref="donut"
                    :data="familiesWithSubFam"
                    :radius-percent="1"
                  />
                </div>
                <div class="section-text">{{ familyDescription }}</div>
              </div>
            </div>
            <div class="section-title">{{ $t('perfume_view_predominant_ingredient') }}</div>
            <quad-pack class="quad-pack" :proportions="perfume.ingredientProportions" :hero-ingredient="perfume.heroIngredient" />
          </div>
          <div class="right">
            <div class="sub-section">
              <div class="section-title">{{ $t('perfume_view_other_ingredients') }}</div>
              <ul class="ingredients-list-text">
                <li v-for="ingredient in ingredients" :key="ingredient" class="ingredients-item-text">{{ ingredient }}</li>
              </ul>
            </div>
            <!-- desktop sub-section -->
            <div v-if="!isMobile" class="sub-section">
              <div class="section-title">{{ $t('perfume_view_families_and_subfamilies') }}</div>
              <div class="perfume-families-groups">
                <div class="donut-wrapper">
                  <donut
                    ref="donut"
                    :data="familiesWithSubFam"
                    :radius-percent="1"
                  />
                </div>
                <div class="section-text">{{ familyDescription }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <not-loged-modal
      :is-recommendation="false"
      :open="openRegisterModal"
      @next="handleGoToLogin"
      @close="openRegisterModal=false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BasicLoading from '../components/basic/BasicLoading.vue'
import BreadcrumbsHeader from '../components/BreadcrumbsHeader.vue'
import Donut from '../components/Donut.vue'
import IngredientItem from '../components/IngredientItem.vue'
import NotLogedModal from '../components/NotLogedModal.vue'
import { QuadPack as QuadPack } from "@bestiario/puig-quad-pack";
import WhyThisRecoDesc from '../components/WhyThisRecoDesc.vue'
import createWatchErrorMixin from '../mixins/createWatchErrorMixin'
import QueryStringMixin from '../mixins/QueryStringMixin'
import TrackingMixin from '../mixins/TrackingMixin'
import { ROUTER_NAMES } from '../settings'
import { capitalize } from '../utils/functionUtils'
import { getFamiliesWithSubFam, getInfoFromEanData } from '../utils/perfumeFunctions'

const ADDED_QUERY_STRING = 'added'

export default {
  components: {
    BasicLoading,
    QuadPack,
    BreadcrumbsHeader,
    IngredientItem,
    Donut,
    NotLogedModal,
    WhyThisRecoDesc
  },
  mixins: [QueryStringMixin, TrackingMixin,createWatchErrorMixin(['perfumeError','selectedPerfumesError'])],
  props: {
    id: { type: [String, Number], required: true },
  },
  data() {
    return {
      selected: 0,
      transitionName: 'right-to-left',
      openRegisterModal: false,
    }
  },
  computed: {
    ...mapGetters({ hasPerfumeFunction: 'profiler/hasPerfumeFunction', user: 'profiler/user', getError:'perfumes/reco/errorById' }),
    errorCode(){
      /** @type {import('axios').AxiosError*/
      const error = this.getError(this.id)
      return error?.response?.status
    },
    hasPerfume() {
      return this.hasPerfumeFunction(this.id)
    },
    familiesWithSubFam() {
      return getFamiliesWithSubFam(this.perfume ? [this.perfume] : [])
    },
    ingredients() {
      if (!this.perfume) return ''
      // ingredientProportions is sorted
      return this.perfume.ingredientProportions.map(ingredientObj => ingredientObj.ingredient.name)
    },
    familyDescription() {
      if (!this.perfume) return ''

      var text = `${this.$t('perfume_view_belong_family')} ${capitalize(this.perfume.family.name)}`
      if(this.perfume.family.name!== this.perfume.secondaryFamily.name)
        text+=` ${this.$t('perfume_view_belong_subfamily')} ${capitalize(this.perfume.secondaryFamily.name)}`
      return text
    },
    imageUrl() {
      const ean = Object.values(this.perfume.eanData).find(ean => ean.imageLink900)
      if (ean) return ean.imageLink900
      else return this.perfume.imageUrl
    },
    breadcrumbs() {
      const bread = [
        {
          label: this.$t('breadcumbs_header_your_recommendation'),
          handleClick: () => this.$routerPush({ name: ROUTER_NAMES.RECOMMENDATIONS }),
        }]
      if(!this.errorCode)
        bread.push({
          label: this.perfume ? this.perfume.name : 'Loading',
          handleClick: () => {},
        },)
      return bread
    },
    selectedPerfumesReady() {
      return this.selectedPerfumes && this.selectedPerfumes.every(p => p!== null)
    },
    userIngredients() {
      return this.selectedPerfumes.flatMap(p => p.ingredientProportions.map(p => p.ingredient))
    },
    mainIngredients() {
      if (!this.selectedPerfumesReady) return []
      return this.perfume.ingredientProportions
        .slice(0, 5)
        .filter(p => this.userIngredients.find(i => i.id === p.ingredient.id))
        .map(p => p.ingredient)
    },
    classification() {
      return this.perfume
        ? getInfoFromEanData(this.perfume, 'classification', false)?.classification
        : ''
    },
    name(){
      if(!this.perfume) return ''
      var title = getInfoFromEanData(this.perfume, 'productBaseProductName', false)?.productBaseProductName
      return title ?? this.perfume.name
    },
    brand(){
      if(!this.perfume) return ''
      var title = getInfoFromEanData(this.perfume, 'brandName', false)?.brandName
      return title ?? this.perfume.brand.name
    }
  },

  watch: {
    selected(newValue, oldValue) {
      if (newValue > oldValue) {
        this.transitionName = 'right-to-left'
      } else this.transitionName = 'left-to-right'
    },
    errorCode(){
      if(this.errorCode){
        var error = {}
        switch(this.errorCode){
          case 404:
            error = {
              message:this.$t('perfume_not_found'),
              object_code:'NotFound 404'
            }
            break
          default:
            error = {
              message:this.$t('perfume_not_found'),
              object_code:this.errorCode
            }
            break
        }
        this.douglasTrack([],[],undefined,error)
      }
    },
    perfume: {
      handler() {
        if (this.perfume) this.douglasTrack([this.perfume])
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.$route.query[ADDED_QUERY_STRING]) {
      this.handleClickWishlist()
      // eslint-disable-next-line
      const { [ADDED_QUERY_STRING]: added, ...otherQueries } = this.$route.query
      this.$router.replace({ query: otherQueries })
    }
    this.eventTracker.emitEvent('VIEW_OPENED', { type: 'PERFUME_VIEWED', data: { id: this.id } })
  },
  methods: {
    ...mapActions({
      pushPerfume: 'profiler/pushPerfume',
      removePerfume: 'profiler/removePerfume',
    }),
    handleClickWishlist() {
      console.log(this.user)
      if (!this.user) {
        this.openRegisterModal = true
        return
      }
      if (!this.hasPerfume) this.pushPerfume(this.id)
      else this.removePerfume(this.id)
    },
    handleClickGoPerfume() {
      const ean = Object.values(this.perfume.eanData).find(ean => ean.linkAbsolut)
      this.eventTracker.emitEvent('ACTION', { type: 'BUY', data: {  id: this.id } })
      if (ean) window.open(ean.linkAbsolut, '_blank')
    },
    handleBack() {
      return this.$routerPush({ name: ROUTER_NAMES.RECOMMENDATIONS })
    },
    handleGoToLogin() {
      const callback = this.$route.fullPath + `&${ADDED_QUERY_STRING}=true`
      this.$routerPush({
        name: ROUTER_NAMES.LOGIN,
        query: {
          callback,
        },
      })
    },
  },
  remoteComputed: {
    perfume: {
      model: 'perfumes/reco',
      method: 'getById',
      params() {
        return [this.id]
      },
    },
    selectedPerfumes: {
      model: 'perfumes/search',
      method: 'getById',
      params() {
        return [this.qsSelectedPerfumes]
      },
    },
  },
}
</script>

<style lang="stylus" scoped>
.perfume-view
  overflow-y: auto
  padding-right: vw(40px)
  padding-left: vw(40px)
  width: 100%
  height: 100%

  .mobile &
    padding: 5%
    // padding-bottom: vw(70px + 5%)
  .not-found
    >>> h1
      max-width: 70%
      color #999
      margin auto
.perfume-view-inner
  margin-right: auto
  margin-left: auto
  max-width: 1280px

.perfume-view-wrapper
  padding-right: vw(80px)
  padding-bottom: vw(80px)
  padding-left: vw(80px)

  .mobile &
    padding-right: 0
    padding-bottom: 0
    padding-left: 0

// .mobile-header
// position: relative

// .perfume-name
// m-font-size(16, 22)
// margin-bottom: vw(10px)
// font-weight: 700

// .perfume-brand
// m-font-size(16, 22)
// margin-bottom: vw(10px)
// font-weight: 700

// .perfume-collection
// m-font-size(14, 22)
// margin-top: vw(10px)

// &:empty
// display: none
.icon-close
  position: absolute
  m-icon("close 2", 20)
  top: 0
  right: 0

.section
  position: relative
  display: flex

  .mobile &
    display: block

  &:not(:last-child)
    margin-bottom: vw(40px)
    padding-bottom: vw(40px)

    .mobile &
      margin-bottom: vw(20px)
      padding-bottom: vw(20px)

  &:not(:last-child)
    border-bottom: 1px solid #e6e6e6

  &.recommendations
    flex-direction: column

  .left,
  .right
    flex: 1 1 0%

  .left
    padding-right: vw(20px)

    .mobile &
      margin-bottom: vw(40px)
      padding-right: 0

  .right
    padding-left: vw(20px)

    .mobile &
      padding-left: 0

.section-title-wrapper
  margin-bottom: vw(20px)
  margin-left: 0

  .mobile &
    margin-top: vw(40px)
    margin-right: 0
    margin-left: 0

.section-title
  m-font-size(20, 26)
  margin-bottom: vw(20px)
  font-weight: 600

.section-sub-title
  m-font-size(16, 26)
  margin-bottom: vw(20px)
  font-weight: 600

.section-text
  m-font-size(16, 26)

  &:not(:last-child)
    margin-bottom: vw(20px)

.sub-section
  &:not(:last-child)
    margin-bottom: vw(80px)

    .mobile &
      margin-bottom: vw(40px)

.perfume-header
  margin-right: vw(30px)

.perfume-name
  m-font-size(18, 30)
  font-weight: 600
.perfume-classification
  m-font-size(12, 30)


.perfume-brand-container
  display: flex
  align-items: center
  margin-bottom: vw(10px)

.perfume-brand
  m-font-size(14, 20)
  font-weight: 800

.perfume-line
  m-font-size(14, 20)
  margin-top: 0.1em
  font-weight: 400

.perfume-separator
  margin-right: vw(10px)
  margin-left: vw(10px)

.perfume-image
  width: 100%
  height: 100%
  background-position: center center
  background-size: contain
  background-repeat: no-repeat

  .mobile &
    margin-bottom: vw(40px)
    height: 40vh

.ingredients-list
  display: flex
  flex-wrap: wrap
  overflow: hidden
  margin-top: vw(40px)
  margin-bottom: vw(80px)

  .mobile &
    margin-top: vw(20px)
    margin-bottom: vw(20px)

  .ingredient-item
    $margin-x = vw(5px)
    $percentage = percentage((1 / 5))
    $width = "calc(%s - (2 * %s))" % ($percentage $margin-x)
    flex: 0 0 $width
    margin: vw(10px) $margin-x
    width: $width
    .mobile &
      $percentage = percentage((1 / 3))
      $width = "calc(%s - (2 * %s))" % ($percentage $margin-x)
      width: $width
      margin: vw(10px) $margin-x
      flex: 0 0 $width
    @media (min-width: 1280px)
      $margin-x = vw(5px)
      $percentage = percentage((1 / 5))
      $width = "calc(%s - (2 * %s))" % ($percentage $margin-x)
      flex: 0 0 $width
      margin: vw(10px) $margin-x
      width: $width

    >>> .label
      line-height unset !important

    >>> .image
      padding-top: 100%
      width: 100%
      height: auto

      &:after
        display: none

.ingredients-list-text
  display: flex
  flex-wrap: wrap

.ingredients-item-text
  m-font-size(16, 26)
  display: flex
  align-items: center
  margin-bottom: vw(5px)

  &:not(:last-child)
    &:after
      display: block
      margin-right: vw(10px)
      margin-left: vw(10px)
      width: 1px
      height: 75%
      background-color: #000
      content: ""

.donut-wrapper
  flex: 0 0 vw(150px)
  margin-right: vw(40px)
  width: vw(150px)
  height: vw(150px)

  .donut
    width: 100%
    height: 100%

.quad-pack
  margin-bottom: vw(20px)
  width: vw(420px)

  .mobile &
    width: 100%

.perfume-families-groups
  display: flex
  align-items: center

.actions
  display: flex
  align-items: center

  .btn
    min-width: vw(160px)
    height: vw(50px)
    flex: 1 1 100%

.recommendation-container
  display: flex
  flex-wrap: wrap

  .perfume-card
    $margin = vw(20px)
    $percentage = percentage((1 / 4))
    $width = "calc(%s - (2 * %s))" % ($percentage $margin)
    flex: 0 0 $width
    margin: $margin
    width: $width
    cursor: pointer

    .mobile &
      $margin = vw(10px)
      $percentage = percentage((1 / 2))
      $width = "calc(%s - (2 * %s))" % ($percentage $margin)
      flex: 0 0 $width
      margin: $margin
      width: $width
</style>