<template>
  <div class="breadcrumbs-header">
    <div class="back-btn" @click="handleBack">{{ $t('back') }}</div>
    <div class="separator" />
    <div class="breadcrumbs">
      <div
        v-for="(link,index) in links"
        :key="index"
        class="crumb"
        :class="{last:index===links.length-1}"
        @click="link.handleClick"
      >
        <div class="crumb-text">{{ link.label }}</div>
        <div class="icon" :class="{last:index===links.length-1}" />
      </div>
    </div>
    <div class="close-btn" @click="handleBack" />
  </div>
</template>

<script>
import { getStacks } from '../router/midleware/stackMiddleware'
import { ROUTER_NAMES } from '../settings'
export default {
  props: {
    links: { type: Array, required: true },
  },
  mounted(){
      const routes = this.$route.matched
      console.log(routes)
      console.log(window.history.length)
  },
  methods: {
    handleBack() {
      if (getStacks().length > 2) return this.$router.back()
      return this.$routerPush({ name: ROUTER_NAMES.RECOMMENDATIONS })
    },
  },
}
</script>

<style lang="stylus" scoped>
.breadcrumbs-header
  display: flex
  align-items: center
  padding: vw(40px) 0
  width: 100%

  .mobile &
    padding: vw(20px)

.back-btn
  m-font-size(14, 20)
  display: flex
  align-items: center
  margin-top: 0.25em
  font-weight: 800
  cursor: pointer

  &:before
    m-icon("arrow-right", 20)
    display: block
    margin-top: -0.25em
    margin-right: vw(5px)
    content: ""
    transform: rotate(180deg)

.separator
  margin: 0 vw(15px)
  width: 1px
  height: vh(20px)
  background-color: #000
  opacity: 0.5

.breadcrumbs
  display: flex
  align-items: center

  .mobile &
    flex: 1 1 0%

.crumb
  m-font-size(14, 20)
  display: flex
  align-items: center
  opacity: 0.5

  .mobile &
    flex: 1 1 0%

  &:not(:last-child)
    margin-right: vw(10px)

  &.last
    opacity: 1

  .crumb-text
    margin-top: 0.25em

  .icon
    m-icon("arrow-right", 20)
    margin-left: vw(5px)

.close-btn
  m-icon("close-black", 24)
  margin-left: auto
  cursor: pointer
</style>