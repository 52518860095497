<template>
  <div class="reco-desc">
    <!-- <div class="intro" v-html="texts.intro"></div> -->
    <div class="content" v-html="texts.content" />
  </div>
</template>

<script>
import { concatWithAnd } from '../utils/functionUtils'
import { getCommonIngredients, getFamiliesWithSubFam } from '../utils/perfumeFunctions'
export default {
  name: 'RecoDesc',
  props: ['data', 'recommendedPerfumes'],
  computed: {
    profileFamiliesWithSubs() {
      return getFamiliesWithSubFam(this.recommendedPerfumes)
    },
    texts() {
      let commonIngs = getCommonIngredients(this.recommendedPerfumes,this.data.ingredientProportions)
      const fullDesc = this.$t('perfume_view_why_text').replace('%ING%',concatWithAnd(commonIngs.map(ingredient => ingredient.name),this.$t('recommendation_and')))
      return { content: fullDesc }
    },
  },
}
</script>

<style>
</style>